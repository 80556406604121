import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34')
];

export const server_loads = [2];

export const dictionary = {
		"/": [~3],
		"/admin": [~4],
		"/artists": [~5],
		"/artists/[slug]": [~6],
		"/contact": [~7],
		"/dashboard": [~8,[2]],
		"/dashboard/account": [~9,[2]],
		"/dashboard/analytics": [~10,[2]],
		"/dashboard/create-artist": [12,[2]],
		"/dashboard/create": [~11,[2]],
		"/dashboard/profile": [~13,[2]],
		"/dashboard/releases": [~14,[2]],
		"/dashboard/reload": [~15,[2]],
		"/dashboard/team": [~16,[2]],
		"/dashboard/tools": [~17,[2]],
		"/dashboard/tracks": [~18,[2]],
		"/demos": [~19],
		"/distribution": [20],
		"/docs": [~21],
		"/faq": [22],
		"/landing": [~23],
		"/newsletter": [~24],
		"/pricing": [~25],
		"/privacy-policy": [26],
		"/releases": [~27],
		"/releases/[slug]": [~28],
		"/services": [29],
		"/sign-in": [30],
		"/sign-up": [31],
		"/support": [~32],
		"/terms-of-service": [33],
		"/upgrade": [~34]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';